<script setup lang="ts">
const props = defineProps<{
  icon?: string
  heading?: string
  title?: string
  subtitle?: string
}>()

// Workaround for unocss to include the icon passed as prop from content
const icons = {
  'heroicons:map-pin-16-solid': 'heroicons:map-pin-16-solid',
  'heroicons:phone-16-solid': 'heroicons:phone-16-solid',
  'heroicons:envelope-16-solid': 'heroicons:envelope-16-solid',
}

const subtitleHref = computed(() => {
  if (!props.subtitle)
    return ''

  const subtitle = props.subtitle.replace(/\s/g, '')

  // check if phone number
  if (subtitle.match(/^\+?\d+$/))
    return `tel:${subtitle}`

  // check if email
  if (subtitle.match(/^[\w.%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i))
    return `mailto:${subtitle}`

  return ''
})
</script>

<template>
  <div class="relative flex items-center gap-x-4">
    <div v-if="icon" class="h10 w10 flex shrink-0 grow-0 basis-auto items-center self-start justify-center rd-full bg-slate-100">
      <NIcon :icon="icons[icon as keyof typeof icons]" />
    </div>

    <div class="text-base leading-6">
      <h3 v-if="heading" class="mb3 fw600 n-text-heading">
        {{ heading }}
      </h3>

      <p class="fw600 n-text-body">
        {{ title }}
      </p>

      <template v-if="subtitle">
        <a v-if="subtitleHref" :href="subtitleHref" class="c-slate500 dark:c-slate700">
          {{ subtitle }}
        </a>
        <p v-else class="c-slate500 dark:c-slate700">
          {{ subtitle }}
        </p>
      </template>
      <div class="c-slate500 dark:c-slate700">
        <slot />
      </div>
    </div>
  </div>
</template>
